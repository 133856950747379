import React from "react";
import { useSelector } from "react-redux";
import { Menu, Row, Space } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../constants/ThemeSetting";
import { BiGridAlt } from "react-icons/bi";
import { RiSchoolFill } from "react-icons/ri";
import { BsMailbox } from "react-icons/bs";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const AdminNav = () => {
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const pathname = useSelector(({ common }) => common.pathname);

  const history = useHistory();
  const getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";
    }
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  return (
    <Menu
      // defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      mode="horizontal"
      style={{ width: "650px" }}
    >
      <SubMenu
        key="dashboard"
        title="Dashboard"
        onTitleClick={() => history.push(`/admin/dashboard`)}
      />
      <SubMenu
        key="users"
        title="Users"
        onTitleClick={() => history.push(`/admin/users/table`)}
      />

      <SubMenu
        key="communities"
        title="Communities"
        onTitleClick={() => history.push(`/admin/communities/table`)}
      />

      <SubMenu
        key="campuses"
        title="Campuses"
        onTitleClick={() => history.push(`/admin/campuses/table`)}
      />

      <SubMenu
        key="notification"
        title="Notification"
        onTitleClick={() => history.push(`/admin/notification`)}
      />
      <SubMenu
        key="mail"
        title="Mail"
        onTitleClick={() => history.push(`/admin/mail`)}
      />

      <SubMenu
        key="posts"
        title="Posts"
        onTitleClick={() => history.push(`/admin/posts/table`)}
      />
    </Menu>
  );
};

AdminNav.propTypes = {};

export default AdminNav;
