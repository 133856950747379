import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input, message } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import IntlMessages from "util/IntlMessages";
import InfoView from "../components/InfoView";

const WrappedNormalLoginForm = (props) => {
  // const token = useSelector(({ auth }) => auth.token);

  const handleSubmit = (formData) => {
    let userData = {};
    userData = {
      name: "Admin Name",
      email: "admin@gmail.com",
      accessType: "admin",
    };
    localStorage.setItem("key", formData.token)
    localStorage.setItem("userData", JSON.stringify(userData));
    props.history.replace("/admin");
    // switch (formData.email) {
    //   case "admin@gmail.com":
    //     userData = {
    //       name: "Admin Name",
    //       email: formData.email,
    //       accessType: "admin",
    //     };
    //     localStorage.setItem("userData", JSON.stringify(userData));
    //     props.history.replace("/admin");
    //     break;
    //   default:
    //     message.error("Invalid email or password");
    //     break;
    // }
  };

  // useEffect(() => {
  //   if (token !== null) {
  //     props.history.replace("/");
  //   }
  // });

  // const { getFieldDecorator } = props.form;

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src="https://via.placeholder.com/272x395" alt="Neature" />
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                Bitsmith Campus Admin Portal
                {/* <IntlMessages id="app.userAuth.signIn" /> */}
              </h1>
              <p>
                Copy token from your phone and paste here to log into the bitsmith campus admin portal
                {/* <IntlMessages id="app.userAuth.bySigning" /> */}
              </p>
              <p>
                {/* <IntlMessages id="app.userAuth.getAccount" /> */}
              </p>
            </div>
            <div className="gx-app-logo">
              <img width={100} alt="Bitsmith Campus" src="https://campus.bitsmith.tech/wp-content/uploads/2023/02/Bitsmith-Wall-Logo.png" />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              onFinish={handleSubmit}
              className="gx-signin-form gx-form-row0"
              layout="vertical"
            >
              <Form.Item
                label="Token"
                name="token"
                rules={[
                  {
                    required: true,
                    message: "Please paste your Token!",
                  },
                ]}
              >
                <Input.TextArea rows={5} placeholder="Paste your Token" />
              </Form.Item>
              {/* <Form.Item
                label="E-mail"
                name="email"
                rules={[
                  {
                    pattern: /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/,
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item> */}

              {/* <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input type="password" placeholder="Password" />
              </Form.Item> */}
              {/* <Form.Item>
                <Checkbox>
                  <IntlMessages id="appModule.iAccept" />
                </Checkbox>
                <span className="gx-signup-form-forgot gx-link">
                  <IntlMessages id="appModule.termAndCondition" />
                </span>
              </Form.Item> */}
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
                {/* <span>
                  <IntlMessages id="app.userAuth.or" />
                </span>{" "}
                <Link to="/signup">
                  <IntlMessages id="app.userAuth.signUp" />
                </Link> */}
              </Form.Item>
              {/* <span className="gx-text-light gx-fs-sm">
                demo user email: 'demo@example.com' and password: 'demo#1234'
              </span> */}
            </Form>
          </div>
          <InfoView />
        </div>
      </div>
    </div>
  );
};

// const WrappedNormalLoginForm = Form.create()(SignIn);

export default WrappedNormalLoginForm;
