import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({ match }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  switch (userData?.accessType) {
    case "admin":
      return (
        <div className="gx-main-content-wrapper">
          <Switch>
            <Route
              path={`${match.url}admin`}
              component={asyncComponent(() => import("./Admin"))}
            />
          </Switch>
        </div>
      );
  }
  // return (
  //   <div className="gx-main-content-wrapper">
  //     <Switch>
  //       {/* <Route
  //       path={`${match.url}sample`}
  //       component={asyncComponent(() => import("./SamplePage"))}
  //     /> */}

  //       <Route
  //         path={`${match.url}college`}
  //         component={asyncComponent(() => import("./College"))}
  //       />
  //       <Route
  //         path={`${match.url}department`}
  //         component={asyncComponent(() => import("./Department"))}
  //       />
  //       <Route
  //         path={`${match.url}subject`}
  //         component={asyncComponent(() => import("./Subject"))}
  //       />
  //       <Route
  //         path={`${match.url}student`}
  //         component={asyncComponent(() => import("./Student"))}
  //       />
  //     </Switch>
  //   </div>
  // );
};

export default App;
